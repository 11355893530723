<!--
 * @Description: 任务单质检详情
 * @Author: ChenXueLin
 * @Date: 2021-09-16 11:15:30
 * @LastEditTime: 2021-09-22 10:57:27
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      :element-loading-background="loadingBackground"
    >
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <detail-title-content :btnType="1" btnText="企业状态:正常">
          </detail-title-content>
          <div class="baseInfo-content-wrap">
            <div class="detailInfo-content">
              <!-- 基本信息 start -->
              <div class="baseInfo-box">
                <div class="edit-title">基本信息</div>
                <el-form label-width="140px" label-position="right">
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="企业ID">
                        1234567
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="企业名称">
                        安装任务
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="管理员">
                        刘德华
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="联系电话"> 12345678 </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="企业类型">
                        2021-08-25
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="创建人">
                        发货计费
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="创建时间"> 202022000 </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
              <!-- 基本信息 end -->
              <!-- 提交信息 start -->
              <div class="contract-info">
                <div class="edit-title" style="margin-bottom:15px;">
                  提交信息
                </div>
                <div class="plateNumber">车辆12333</div>
                <div class="user-title">安装设备</div>
                <el-table border :data="tableData" highlight-current-row>
                  <el-table-column
                    show-overflow-tooltip
                    v-for="(column, index) in columnData"
                    :key="index"
                    :prop="column.fieldName"
                    :label="column.fieldLabel"
                    :min-width="column.width"
                    :fixed="column.fixed"
                    :align="column.align"
                    header-align="center"
                  >
                  </el-table-column>
                </el-table>
                <div class="user-title" style="margin-top:20px;">时间节点</div>
                <el-table border :data="tableData" highlight-current-row>
                  <el-table-column
                    show-overflow-tooltip
                    v-for="(column, index) in columnData"
                    :key="index"
                    :prop="column.fieldName"
                    :label="column.fieldLabel"
                    :min-width="column.width"
                    :fixed="column.fixed"
                    :align="column.align"
                    header-align="center"
                  >
                  </el-table-column>
                </el-table>
              </div>
              <!-- 属性信息 end -->
              <!-- 照片信息墙 start -->
              <div class="contract-info">
                <div class="user-title" style="margin-top:20px;">照片信息</div>
                <div class="imgs">
                  <div
                    class="img-item"
                    v-for="(item, index) in arr"
                    :key="index"
                  >
                    <img
                      src="https://e6yun-30.oss-cn-shenzhen.aliyuncs.com/logo/smalllogo3.png"
                      alt=""
                    />
                    <div class="picture-name">车辆正面照片</div>
                    <div class="picture-time">2021-05-21</div>
                    <div class="picture-score">
                      <el-input placeholder="请输入评分1~10"></el-input>
                    </div>
                  </div>
                  <div class="img-item img-hidden"></div>
                  <div class="img-item img-hidden"></div>
                  <div class="img-item img-hidden"></div>
                  <div class="img-item img-hidden"></div>
                </div>
              </div>
              <!-- 照片信息墙 end -->
            </div>
          </div>
        </div>
        <div class="edit-wrapper__footer">
          <el-button class="cancel">取消</el-button>
          <el-button type="primary">确定</el-button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import detailTitleContent from "@/components/detailTitleContent";
export default {
  name: "",
  components: {
    detailTitleContent
  },
  data() {
    return {
      arr: [1, 2, 3, 4, 5, 6, 7, 8],
      columnData: [
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "安装ID",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userName",
          display: true,
          fieldLabel: "安装设备",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "phone",
          display: true,
          fieldLabel: "状态",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      imgs: [1, 2, 3]
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {},
  methods: {},
  created() {}
};
</script>
<style lang="scss" scoped>
// .main-wrapper .edit-wrapper .edit-wrapper__body {
//   height: 100%;
// }
.plateNumber {
  width: 100%;
  height: 70px;
  text-align: center;
  line-height: 70px;
  font-size: 18px;
}
.user-title {
  width: calc(100% - 30px);
  margin: 0 auto;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  background: #f6f7fb;
  font-size: 14px;
  padding-left: 20px;
  box-sizing: border-box;
  color: #505155;
}
.imgs {
  display: flex;
  margin-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  .img-item {
    margin-bottom: 20px;
    & > img {
      width: 240px;
      height: 160px;
      border-radius: 20px;
    }
    .picture-name {
      margin-top: 20px;
      font-size: 16px;
      color: #5f5f5f;
      text-align: center;
    }
    .picture-time {
      font-size: 14px;
      color: #909195;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .picture-score {
      text-align: center;
      .el-input {
        width: 130px;
        margin: 0 auto;
      }
    }
  }
  .img-hidden {
    width: 240px;
    height: 0px;
  }
}
</style>
